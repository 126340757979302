import 'normalize-css';
import './sass/main.scss';
import 'owl.carousel';

$(".principles__slider").owlCarousel({
    margin: 20,
    autoplay:true,
    autoplayTimeout:2000,
    autoplayHoverPause:true,
    loop: true,
    responsive: {
        0: {
            items:1,
            
        },
        576: {
        
            items:2,
        },
        960: {
      
            items:3,
        },
        1280: {
      
            items:4,
        }

    
    }
});
